import { Form, FormLabel, Nav } from "react-bootstrap";
import { darken, lighten } from "polished";

import { Link } from "react-router-dom";
import styled from "styled-components";

export const ServicoParagraph = styled.footer`
  color: ${(props) => props.theme.mainColor} !important;
`;

export const ServicoParagraphGreen = styled.footer`
  color: ${(props) => props.theme.secondColor} !important;
`;

export const ServicoLi = styled.footer`
  color: ${(props) => props.theme.mainColor} !important;
`;

export const ServicoLiPoint = styled.li`
  color: ${(props) => props.theme.secondColor} !important;
`;

export const ButtonMain = styled.button`
  color: ${(props) => props.theme.mainColor};
  background-color: ${(props) => props.theme.secondColor};
  border: none;
  min-width: 150px;
  &:hover {
    background-color: ${(props) => darken(0.2, props.theme.secondColor)};
    color: ${(props) => props.theme.mainColor};
  }
`;

export const ButtonSecond = styled.button`
  color: ${(props) => props.theme.secondColor};
  background-color: ${(props) => props.theme.mainColor};
  border: none;
  min-width: 150px;
  &:hover {
    background-color: ${(props) => lighten(0.2, props.theme.mainColor)};
    color: ${(props) => props.theme.secondColor};
  }
`;

export const ButtonSecondBorder = styled.button`
  color: ${(props) => props.theme.secondColor};
  background-color: ${(props) => props.theme.mainColor};
  border-width: 1px;
  border-color: ${(props) => props.theme.secondColor};
  min-width: 150px;
  &:hover {
    background-color: ${(props) => lighten(0.2, props.theme.mainColor)};
    color: ${(props) => props.theme.secondColor};
  }
`;

export const ServicoLink = styled(Link)`
  color: ${(props) => props.theme.secondColor} !important;
  &:hover {
    color: ${(props) => lighten(0.2, props.theme.secondColor)} !important;
  }
`;

export const ServicoA = styled.a`
  color: ${(props) => props.theme.secondColor} !important;
  &:hover {
    color: ${(props) => lighten(0.2, props.theme.secondColor)} !important;
  }
`;

export const ServicoSpan = styled.span`
  color: ${(props) => props.theme.secondColor} !important;
  &:hover {
    color: ${(props) => lighten(0.2, props.theme.secondColor)} !important;
  }
`;

export const ServicoFAQLink = styled.a`
  font-size: 1rem;
  color: ${(props) => props.theme.mainColor} !important;
  &:hover {
    color: ${(props) => props.theme.secondColor} !important;
  }
`;

export const ServicoFAQIcon = styled.div`
  height: 30px;
  width: 30px;
  color: ${(props) => props.theme.mainColor};
  text-align: center;
  border-radius: 50%;
  float: left;
  font-weight: bold;
  line-height: 30px;
  background-color: ${(props) => lighten(0.2, props.theme.secondColor)};
`;

export const ServicoIcon = styled.i`
  color: ${(props) => props.theme.secondColor};
`;

export const ServicoH1 = styled.h1`
  color: ${(props) => props.theme.mainColor};
`;

export const ServicoH4Green = styled.h4`
  color: ${(props) => props.theme.secondColor};
`;

export const ServicoH3Green = styled.h3`
  color: ${(props) => props.theme.secondColor};
`;

export const ServicoH5WithBackground = styled.h5`
  color: ${(props) => props.theme.secondColor};
  background-color: ${(props) => props.theme.mainColor};
`;

export const ServicoH5WithBackgroundGreen = styled.h5`
  color: ${(props) => props.theme.mainColor};
  background-color: ${(props) => props.theme.secondColor};
`;

export const ServicoSelect = styled(Form.Check)`
  .form-check-input:checked {
    background-color: ${(props) => props.theme.secondColor};
    border-color: ${(props) => props.theme.secondColor};
  }

  .form-check-label {
    color: ${(props) => props.theme.secondColor};
  }
`;

export const ServicoNavLink = styled(Nav.Link)`
  &:hover {
    background-color: ${({ theme }) => theme.secondColor};
    color: ${({ theme }) => theme.mainColor};
  }

  ${({ active, theme }) =>
    active &&
    `
    background-color: ${theme.secondColor} !important;
    color: ${theme.mainColor} !important;
  `}
`;

export const ServicoModal = styled.div`
  background-color: ${({ theme }) => theme.mainColor} !important;
`;

export const ServicoTooltipIcon = styled.i`
  color: ${(props) => props.theme.secondColor} !important;
  &:hover {
    cursor: pointer !important;
  }
`;

export const ServicoFormLabel = styled(FormLabel)`
  color: ${(props) => props.theme.secondColor} !important;
`;

// Define the Button component using styled-components
export const ServicoFaqButton = styled.button`
  color: ${(props) => props.theme.mainColor};
  background-color: white;
  border: none;
  text-align: left;
  cursor: pointer;
  transition: color 0.3s ease, border-color 0.3s ease;

  &:hover {
    color: ${(props) => props.theme.secondColor};
    border-color: ${(props) => props.theme.secondColor};
  }
`;

export const ServicoSubparagraphGreen = styled.p`
  color: ${(props) => props.theme.secondColor} !important;
  opacity: 0.5;
`;

export const ServicoSubparagraphGreenNoOpacity = styled.p`
  color: ${(props) => props.theme.secondColor} !important;
`;

export const ServicoImg = styled.img`
  max-width: 500px; /* Change this value as needed */
  max-height: 500px; /* Change this value as needed */
  width: 100%;
  height: auto;
`;

export const ServicoSubImg = styled.img`
  max-width: 100px; /* Change this value as needed */
  max-height: 100px; /* Change this value as needed */
  width: auto;
  height: auto;
`;
