export const EditOfferPageResourcesEN: IEditOfferPageResources = {
  title: "Task Edit",
  button: "Update Task",
  general: "GENERAL",
  productImages: "TASK IMAGES",
  boostTypeLabel: "Boost Type",
  offerStatus: "Task Status",
  infos: {
    open: "The task is open and can be edited.",
    closed: "The task is closed and can't be edited.",
    reserved: "The task has been reserved and can only be viewed.",
  },
  modal: {
    title: "Successfull!",
    text: "The task has been successfully updated.",
    button: "Done",
  },
  errors: {
    notFound: "The requested task has not been found.",
    edit: "The task could not be updated.",
    registerPic: "The picture you are trying to upload is too large.",
  },
  fields: {
    postalCode: {
      label: "ZIP code",
      name: "postalCode",
      error: "Please enter a valid ZIP code",
      validation: "Wrong entry.",
    },
    country: {
      label: "Country",
      name: "country",
      error: "Please select a valid country.",
      validation: "Wrong entry.",
      countries: {
        AUSTRIA: "Austria",
      },
    },
    cityId: {
      label: "City",
      name: "cityId",
      error: "Please select a valid city.",
      validation: "Wrong entry.",
      cities: {
        AUSTRIA: {
          VIENNA: "Vienna",
        },
      },
    },
    name: "User Name: ",
    title: {
      name: "title",
      label: "Title",
      error: "Please enter the task title.",
    },
    description: {
      name: "description",
      label: "Description",
      error: "Please enter the task description.",
    },
    category: {
      name: "category",
      label: "Category",
      error: "Please select the task category.",
    },
    images: {
      name: "images",
      label: "Upload task image(s)",
      error: "Please upload the task image(s).",
    },
    showMobile: {
      label: "Show mobile number",
    },
  },
};

export const EditOfferPageResourcesDE: IEditOfferPageResources = {
  title: "Auftrag bearbeiten",
  button: "Auftrag aktualisieren",
  general: "GENEREL",
  productImages: "AUFTRAGSBILDER",
  boostTypeLabel: "Boost Type",
  offerStatus: "Auftragsstatus",
  infos: {
    open: "Der Auftrag ist geöffnet und kann bearbeitet werden.",
    closed: "Der Auftrag ist geschlossen und kann nicht bearbeitet werden.",
    reserved: "Der Auftrag ist reserviert und kann nur eingesehen werden.",
  },
  modal: {
    title: "Erfolgreich!",
    text: "Der Auftrag wurde erfolgreich aktualisiert.",
    button: "Fertig",
  },
  errors: {
    notFound: "Der angeforderte Auftrag wurde nicht gefunden.",
    edit: "Der Auftrag konnte nicht aktualisiert werden.",
    registerPic: "Das Foto dass sie hochladen wollen ist zu groß.",
  },
  fields: {
    postalCode: {
      label: "PLZ",
      name: "postalCode",
      error: "Bitte geben Sie eine gültige Postleitzahl ein.",
      validation: "Falsche Eingabe.",
    },
    country: {
      label: "Land",
      name: "country",
      error: "Bitte wählen Sie ein gültiges Land aus.",
      validation: "Falsche Eingabe.",
      countries: {
        AUSTRIA: "Österreich",
      },
    },
    cityId: {
      label: "Stadt",
      name: "cityId",
      error: "Bitte wählen Sie eine gültige Stadt aus.",
      validation: "Falsche Eingabe.",
      cities: {
        AUSTRIA: {
          VIENNA: "Wien",
        },
      },
    },
    name: "User Name: ",
    title: {
      name: "title",
      label: "Titel",
      error: "Bitte geben Sie den Auftragstitel ein.",
    },
    description: {
      name: "description",
      label: "Beschreibung",
      error: "Bitte geben Sie die Auftragsbeschreibung ein.",
    },
    category: {
      name: "category",
      label: "Kategorie",
      error: "Bitte wählen Sie die Auftragskategorie aus.",
    },
    images: {
      name: "images",
      label: "Auftragsbild hochladen.",
      error: "Bitte laden Sie die Auftragsbilder hoch.",
    },
    showMobile: {
      label: "Handynummer anzeigen",
    },
  },
};

interface Modal {
  title: string;
  text: string;
  button: string;
}

interface ErrorMessages {
  notFound: string;
  edit: string;
  registerPic: string;
}

interface FieldValidation {
  label: string;
  name: string;
  error: string;
  validation?: string;
}

interface CountryField extends FieldValidation {
  countries: Record<string, string>;
}

interface CityField extends FieldValidation {
  cities: Record<string, Record<string, string>>;
}

interface Fields {
  postalCode: FieldValidation;
  country: CountryField;
  cityId: CityField;
  name: string;
  title: FieldValidation;
  description: FieldValidation;
  category: FieldValidation;
  images: FieldValidation;
  showMobile: {
    label: string;
  };
}

interface Infos {
  open: string;
  closed: string;
  reserved: string;
}

export interface IEditOfferPageResources {
  title: string;
  button: string;
  general: string;
  productImages: string;
  boostTypeLabel: string;
  offerStatus: string;
  infos: Infos;
  modal: Modal;
  errors: ErrorMessages;
  fields: Fields;
}
