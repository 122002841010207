import {
  ButtonMain,
  ServicoParagraphGreen,
} from "../../components/Servico/ServicoStyled";

import { TextItem } from "../../components/TextCarousel/TextCarousel";
import img1 from "./images/img1.jpg";
import img2 from "./images/img2.jpg";

export const HomeResourcesEN: IHomeResources = {
  news: {
    title: "News",
    button: "Read more",
    news1: {
      title: "Welcome to servi.co",
      description:
        "Dear Service Providers, Welcome to servi.co, the online intermediary platform that connects service providers of all kinds with households or businesses that need your services.",
      path: "servico",
    },
    news2: {
      title:
        "Step-by-Step Guide to Registering a Sole Proprietorship (Einzelunternehmen) in Austria",
      description:
        "Starting a sole proprietorship (Einzelunternehmen) in Austria is relatively straightforward. Below, you will find a step-by-step guide, along with helpful links and tips to simplify the process.",
      path: "einzelunternehmen",
    },
    news3: {
      title:
        "The Risks and Consequences of Providing Unregistered Services in Austria",
      description:
        " Austria is known for its high standards in business practices and a strict legal framework. While it may be tempting to offer services without fulfilling all legal requirements, such actions can have significant consequences. This text highlights the legal and financial risks associated with the lack of necessary certifications, undeclared work, and tax evasion.",
      path: "risiken",
    },
  },
  title: (register) => (
    <>
      <h1 style={{ color: "white" }}>
        Do you want to take your business to the next level?
      </h1>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ButtonMain
          onClick={register}
          style={{ maxWidth: "220px", height: "60px" }}
          className="btn mt-2"
        >
          REGISTER NOW
        </ButtonMain>
      </div>
    </>
  ),
  noOffersPlaceholder: "Ready, set, jobs ahead!",
  carouselItems: [
    {
      title: "ACTION",
      paragraph: "Free membership for new service providers until 2026.",
    },
    {
      title: "NON-BINDING",
      paragraph:
        "Decide after the trial period whether you want to continue using it.",
    },
    {
      title: "PRICE FAIRNESS",
      paragraph: "Transparent and without hidden costs.",
    },
    {
      title: "REPUTATION BUILDING",
      paragraph: "Enhance your reputation and secure more jobs.",
    },
  ],
  latestTitle: "Latest tasks",
  mostPopular: "Most popular tasks",
  messageBtn: "Open",
  modal: {
    title: "How to save servi.co shortcut to your Home screen?",
    text: () => (
      <>
        <ServicoParagraphGreen className="mt-3 ">
          <b>For iOS (iPhone) users:</b>
        </ServicoParagraphGreen>
        <ServicoParagraphGreen>1. Tap the Share icon.</ServicoParagraphGreen>
        <img
          src={img1}
          alt="Teilen Symbol"
          style={{
            maxHeight: "100px",
            marginBottom: "2rem",
            marginTop: "1rem",
            maxWidth: "240px",
          }}
        />
        <ServicoParagraphGreen>
          2. Tap „Add to Home Screen“.
        </ServicoParagraphGreen>
        <ServicoParagraphGreen>3. Tap the Add Button.</ServicoParagraphGreen>
        <ServicoParagraphGreen className="mt-3 ">
          <b>For Android users:</b>
        </ServicoParagraphGreen>
        <ServicoParagraphGreen>
          1. Tap the Menu icon (three vertical dots in the top-right corner).
        </ServicoParagraphGreen>
        <img
          src={img2}
          alt="Teilen Symbol"
          style={{
            maxHeight: "100px",
            marginBottom: "2rem",
            marginTop: "1rem",
            maxWidth: "240px",
          }}
        />
        <ServicoParagraphGreen>
          2. Select “Add to Home screen”.
        </ServicoParagraphGreen>
        <ServicoParagraphGreen>3. Tap “Add”.</ServicoParagraphGreen>
      </>
    ),
    button: "Don't show this again",
    buttonOk: "OK",
  },
};

export const HomeResourcesDE: IHomeResources = {
  news: {
    title: "Neuigkeiten",
    button: "Mehr erfahren",
    news1: {
      title: "Willkommen bei servi.co",
      description:
        "Willkommen bei servi.co, der Online-Vermittlungsplattform, die Dienstleister aller Art mit Haushalten oder Unternehmen zusammenbringt, die genau Ihre Dienstleistungen benötigen.",
      path: "servico",
    },
    news2: {
      title:
        "Schritt-für-Schritt-Anleitung zur Anmeldung eines Einzelunternehmens in Österreich",
      description:
        "Die Gründung eines Einzelunternehmens (Ein-Personen-Unternehmen) in Österreich ist vergleichsweise unkompliziert. Nachfolgend finden Sie eine Schritt-für-Schritt-Anleitung sowie hilfreiche Links und Tipps, um den Prozess zu erleichtern.",
      path: "einzelunternehmen",
    },
    news3: {
      title:
        "Die Risiken und Konsequenzen nicht registrierter Dienstleistungen in Österreich",
      description:
        "Österreich ist bekannt für hohe Standards im Geschäftsleben und ein strenges rechtliches Rahmenwerk. Auch wenn es verlockend sein mag, Dienstleistungen ohne Erfüllung aller gesetzlichen Anforderungen anzubieten, können solche Handlungen erhebliche Konsequenzen haben. Dieser Text beleuchtet die rechtlichen und finanziellen Risiken, die mit dem Fehlen notwendiger Zertifikate, Schwarzarbeit und Steuerhinterziehung einhergehen.",
      path: "risiken",
    },
  },
  title: (register) => (
    <>
      <h1 style={{ color: "white" }}>
        Möchten Sie Ihr Geschäft auf die nächste Stufe bringen?
      </h1>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ButtonMain
          onClick={register}
          style={{ maxWidth: "220px", height: "60px" }}
          className="btn mt-2"
        >
          JETZT REGISTRIEREN
        </ButtonMain>
      </div>
    </>
  ),
  noOffersPlaceholder: "Auf die Plätze, bald geht's los!",
  carouselItems: [
    {
      title: "AKTION",
      paragraph: "Kostenlose Mitgliedschaft für neue Dienstleister bis 2026.",
    },
    {
      title: "UNVERBINDLICH",
      paragraph:
        "Entscheiden Sie nach der Probezeit, ob Sie es weiter nutzen wollen.",
    },
    {
      title: "PREISFAIRNESS",
      paragraph: "Transparent und ohne versteckte Kosten.",
    },
    {
      title: "RUFSTÄRKUNG",
      paragraph: "Steigern Sie Ihr Ansehen und erhalten Sie mehr Aufträge.",
    },
  ],
  modal: {
    title:
      "Wie speichert man die servi.co-Verknüpfung auf dem Startbildschirm?",
    text: () => (
      <>
        <ServicoParagraphGreen className="mt-3 ">
          <b>Für iOS (iPhone)-Nutzer:innen:</b>
        </ServicoParagraphGreen>
        <ServicoParagraphGreen>
          1. Tippen Sie auf das Teilen-Symbol.
        </ServicoParagraphGreen>
        <img
          src={img1}
          alt="Teilen Symbol"
          style={{
            maxHeight: "100px",
            marginBottom: "2rem",
            marginTop: "1rem",
            maxWidth: "240px",
          }}
        />
        <ServicoParagraphGreen>
          2. Tippen Sie auf „Zum Home-Bildschirm“.
        </ServicoParagraphGreen>
        <ServicoParagraphGreen>
          3. Tippen Sie „Hinzufügen“.
        </ServicoParagraphGreen>
        <ServicoParagraphGreen className="mt-3 ">
          <b>Für Android-Nutzer:innen</b>
        </ServicoParagraphGreen>
        <ServicoParagraphGreen>
          1. Tippen Sie auf das Menü-Symbol (drei vertikale Punkte in der oberen
          rechten Ecke).
        </ServicoParagraphGreen>
        <img
          src={img2}
          alt="Teilen Symbol"
          style={{
            maxHeight: "100px",
            marginBottom: "2rem",
            marginTop: "1rem",
            maxWidth: "240px",
          }}
        />
        <ServicoParagraphGreen>
          2. Wählen Sie „Zum Startbildschirm hinzufügen“.
        </ServicoParagraphGreen>
        <ServicoParagraphGreen>
          3. Tippen Sie auf „Hinzufügen“.
        </ServicoParagraphGreen>
      </>
    ),
    button: "Nicht mehr anzeigen",
    buttonOk: "OK",
  },
  messageBtn: "Öffnen",
  latestTitle: "Aufträge",
  mostPopular: "Beliebtesten Aufträge",
};

export interface IHomeResources {
  title: (register: () => void) => React.ReactElement;
  messageBtn: string;
  latestTitle: string;
  mostPopular: string;
  noOffersPlaceholder: string;
  carouselItems: TextItem[];
  news: {
    title: string;
    button: string;
    news1: {
      title: string;
      description: string;
      path: string;
    };
    news2: {
      title: string;
      description: string;
      path: string;
    };
    news3: {
      title: string;
      description: string;
      path: string;
    };
  };
  modal: {
    title: string;
    text: () => React.ReactElement;
    button: string;
    buttonOk: string;
  };
}
