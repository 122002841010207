export interface INewsResources {
  [key: string]:
    | {
        title: string;
        text: () => React.ReactElement;
      }
    | string; // Allow `notFound` to be a string
}

export const NewsResourcesDE: INewsResources = {
  risiken: {
    title:
      "Die Risiken und Konsequenzen nicht registrierter Dienstleistungen in Österreich",
    text: () => (
      <>
        <p>
          Österreich ist bekannt für hohe Standards im Geschäftsleben und ein
          strenges rechtliches Rahmenwerk. Auch wenn es verlockend sein mag,
          Dienstleistungen ohne Erfüllung aller gesetzlichen Anforderungen
          anzubieten, können solche Handlungen erhebliche Konsequenzen haben.
          Dieser Text beleuchtet die rechtlichen und finanziellen Risiken, die
          mit dem Fehlen notwendiger Zertifikate, Schwarzarbeit und
          Steuerhinterziehung einhergehen.
        </p>
        <section>
          <h2>Konsequenzen von Dienstleistungen ohne notwendige Zertifikate</h2>
          <p>
            In Österreich erfordern viele Berufe spezifische Zertifikate oder
            Qualifikationen, um die öffentliche Sicherheit, hohe
            Qualitätsstandards und den Verbraucherschutz zu gewährleisten.
            Beispiele sind Elektriker, Klempner und Gesundheitsdienstleister.
          </p>
          <h3>Potenzielle Risiken:</h3>
          <ul>
            <li>
              <strong>Rechtliche Strafen:</strong> Das Arbeiten ohne
              erforderliche Zertifikate ist illegal und kann zu Geldstrafen,
              rechtlicher Verfolgung oder sogar einem Geschäftsverbot führen.
            </li>
            <li>
              <strong>Rufschädigung:</strong> Kunden, die herausfinden, dass
              Ihnen die erforderlichen Zertifikate fehlen, könnten das Vertrauen
              in Ihre Dienstleistungen verlieren.
            </li>
            <li>
              <strong>Haftungsprobleme:</strong> Bei Unfällen oder Schäden
              können Sie persönlich haftbar gemacht werden, ohne den Schutz
              einer gültigen Versicherung oder die Einhaltung gesetzlicher
              Standards.
            </li>
          </ul>
          <h3>Beispiel:</h3>
          <p>
            Ein nicht zertifizierter Elektriker, der Installationen durchführt,
            könnte Sicherheitsstandards nicht einhalten. Das Risiko von Unfällen
            steigt, und mögliche Haftungsfolgen können finanzielle
            Entschädigungsforderungen oder sogar strafrechtliche Anklagen
            umfassen.
          </p>
        </section>
        <section>
          <h2>Gefahren der Schwarzarbeit</h2>
          <p>
            Die Durchführung von Schwarzarbeit mag kurzfristig verlockend sein,
            birgt jedoch schwerwiegende langfristige Konsequenzen.
          </p>
          <h3>Hauptgefahren:</h3>
          <ul>
            <li>
              <strong>Hohe Geldstrafen und strafrechtliche Verfolgung:</strong>{" "}
              Österreichische Behörden überwachen aktiv Steuerhinterziehung und
              Schwarzarbeit. Strafen können hohe Bußgelder und Freiheitsstrafen
              umfassen.
            </li>
            <li>
              <strong>Kein rechtlicher Schutz:</strong> Ohne formelle
              Geschäftsstruktur fehlt es an rechtlichem Schutz, um
              Streitigkeiten zu klären oder Verträge durchzusetzen.
            </li>
            <li>
              <strong>Kein Zugang zu Sozialleistungen:</strong> Schwarzarbeit
              bedeutet den Verzicht auf Beiträge zu Sozialversicherung, Pension
              und Krankenversicherung.
            </li>
            <li>
              <strong>Ruf und Vertrauen:</strong> Einmal als Schwarzarbeiter
              identifiziert, kann dies Ihren Ruf in der Branche dauerhaft
              schädigen.
            </li>
          </ul>
          <h3>Beispiel:</h3>
          <p>
            Ein Handwerker, der bei Schwarzarbeit erwischt wird, könnte mit
            Bußgeldern in Höhe von mehreren tausend Euro belegt werden und
            müsste nachträglich Steuern sowie zusätzliche Strafen zahlen.
          </p>
        </section>
        <section>
          <h2>Hilfreiche Links und Ressourcen</h2>
          <ul>
            <li>
              <a href="https://www.wko.at">WKO - Unternehmensgründung</a>
            </li>
            <li>
              <a href="https://www.bmf.gv.at">
                Österreichisches Finanzministerium (BMF)
              </a>
            </li>
            <li>
              <a href="https://www.oesterreich.gv.at">
                E-Government Österreich
              </a>
            </li>
            <li>
              <a href="https://www.svs.at">
                SVS - Sozialversicherung für Selbständige
              </a>
            </li>
          </ul>
        </section>
        <section>
          <h2>Haftungsausschluss</h2>
          <p>
            Dieser Text dient ausschließlich Informationszwecken. Obwohl wir
            bemüht sind, die bereitgestellten Informationen korrekt
            darzustellen, können wir keine Gewähr für deren Richtigkeit
            übernehmen. Der Inhalt basiert auf unseren Recherchen und deckt
            möglicherweise nicht alle Aspekte oder Änderungen der Gesetzgebung
            ab. Für detaillierte und personalisierte Informationen empfehlen
            wir, sich direkt an die zuständigen Institutionen und Behörden zu
            wenden.
          </p>
        </section>
      </>
    ),
  },
  servico: {
    title: "Willkommen bei servi.co",
    text: () => (
      <>
        <p>
          Willkommen bei <strong>servi.co</strong>, der
          Online-Vermittlungsplattform, die Dienstleister aller Art mit
          Haushalten oder Unternehmen zusammenbringt, die genau Ihre
          Dienstleistungen benötigen.
        </p>
        <section>
          <h2>Unser Spezialangebot</h2>
          <p>
            Wir laden Sie herzlich ein, Teil von <strong>servi.co</strong> zu
            werden und von unserem aktuellen Spezialangebot zu profitieren:
          </p>
          <p>
            <strong>
              KOSTENLOSE UND UNVERBINDLICHE MITGLIEDSCHAFT BIS 2026
            </strong>
          </p>
          <p>
            Dieses exklusive Angebot gilt für alle Dienstleister, die sich bis
            zum <strong>31.03.2025</strong> registrieren.
          </p>
          <p>
            Nach Ablauf der kostenlosen Testphase entscheiden Sie selbst, ob Sie
            die Plattform weiterhin nutzen möchten.
          </p>
        </section>
        <section>
          <h2>Erfahren Sie mehr</h2>
          <p>
            Erfahren Sie mehr über uns auf der Seite{" "}
            <a href="https://servi.co/aboutus">ÜBER UNS</a> und werfen Sie einen
            Blick in unsere{" "}
            <a
              href="https://servi.co/pdf/TermsConditions.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Allgemeinen Geschäftsbedingungen
            </a>
            , um alle Details zu entdecken.
          </p>
        </section>
        <section>
          <h2>Schließen Sie sich uns an</h2>
          <p>
            Schließen Sie sich <strong>servi.co</strong> an und heben Sie Ihr
            Geschäft auf das nächste Level!
          </p>
          <p>Wir wünschen Ihnen viel Erfolg! 😊</p>{" "}
        </section>
      </>
    ),
  },
  einzelunternehmen: {
    title:
      "Schritt-für-Schritt-Anleitung zur Anmeldung eines Einzelunternehmens in Österreich",
    text: () => (
      <>
        <p>
          Die Gründung eines Einzelunternehmens (Ein-Personen-Unternehmen) in
          Österreich ist vergleichsweise unkompliziert. Nachfolgend finden Sie
          eine Schritt-für-Schritt-Anleitung sowie hilfreiche Links und Tipps,
          um den Prozess zu erleichtern.
        </p>

        <h2>1. Überprüfen Sie die Voraussetzungen</h2>
        <ul>
          <li>
            <strong>Berufsspezifische Anforderungen:</strong> Prüfen Sie, ob für
            Ihre geplante Tätigkeit spezielle Nachweise oder Zertifikate
            erforderlich sind, z. B. ein Meisterbrief bei reglementierten
            Gewerben (z. B. Elektriker, Installateure).
          </li>
          <li>
            <strong>Rechtsform:</strong> Als Einzelunternehmer benötigen Sie
            keine besondere Rechtsform, da Sie als natürliche Person tätig
            werden.
          </li>
        </ul>
        <p>
          <a href="https://www.wko.at">
            Reglementierte und freie Gewerbe - WKO
          </a>
        </p>

        <h2>2. Gewerbeanmeldung</h2>
        <p>
          Melden Sie Ihr Gewerbe bei der zuständigen Behörde an. Dies kann
          online oder persönlich erfolgen.
        </p>
        <ul>
          <li>
            Zuständig sind die Bezirkshauptmannschaft, das Magistrat oder die
            Stadtgemeinde, abhängig von Ihrem Wohnsitz.
          </li>
          <li>
            Benötigte Unterlagen:
            <ul>
              <li>GISA-Formular (erhältlich bei der Behörde oder online)</li>
              <li>
                Gültiger Lichtbildausweis (Reisepass oder Personalausweis)
              </li>
              <li>Meldezettel</li>
              <li>
                Nachweise über erforderliche Qualifikationen (bei
                reglementierten Gewerben)
              </li>
            </ul>
          </li>
        </ul>
        <p>
          <a href="https://www.oesterreich.gv.at">Online-Gewerbeanmeldung</a>
        </p>

        <h2>3. Anmeldung beim Finanzamt</h2>
        <p>
          Nach der Gewerbeanmeldung erhalten Sie automatisch eine
          Benachrichtigung vom Finanzamt. Sie müssen dort eine Steuernummer
          beantragen, um steuerpflichtige Einnahmen zu melden.
        </p>
        <ul>
          <li>
            Bei der Anmeldung beim Finanzamt müssen Sie das Formular Verf 24
            ausfüllen.
          </li>
          <li>
            Vergessen Sie nicht, anzugeben, ob Sie umsatzsteuerpflichtig sind
            (Umsatzgrenze: EUR 35.000 jährlich).
          </li>
        </ul>
        <p>
          <a href="https://www.bmf.gv.at">
            Formulare und Steuerinformationen - BMF
          </a>
        </p>

        <h2>4. Anmeldung bei der Sozialversicherung der Selbständigen (SVS)</h2>
        <p>
          Alle Einzelunternehmer sind verpflichtet, sich bei der
          Sozialversicherungsanstalt der Selbständigen (SVS) anzumelden.
        </p>
        <ul>
          <li>
            Sie erhalten eine Versicherungsnummer und zahlen Beiträge zur
            Kranken-, Unfall- und Pensionsversicherung.
          </li>
          <li>
            Die Anmeldung erfolgt automatisch nach der Gewerbeanmeldung, Sie
            sollten jedoch sicherstellen, dass die Anmeldung korrekt
            durchgeführt wurde.
          </li>
        </ul>
        <p>
          <a href="https://www.svs.at">SVS - Anmeldung für Selbständige</a>
        </p>

        <h2>5. Registrierung der Umsatzsteuer (falls erforderlich)</h2>
        <p>
          Wenn Ihr Jahresumsatz 35.000 Euro übersteigt, müssen Sie sich für die
          Umsatzsteuer registrieren lassen. Sie erhalten eine UID-Nummer
          (Umsatzsteuer-Identifikationsnummer).
        </p>
        <ul>
          <li>
            Falls Ihr Umsatz unter dieser Grenze liegt, können Sie die
            Kleinunternehmerregelung nutzen, sind dann aber nicht
            vorsteuerabzugsberechtigt.
          </li>
        </ul>
        <p>
          <a href="https://www.bmf.gv.at">Umsatzsteuer-Informationen - BMF</a>
        </p>

        <h2>6. Mitgliedschaft bei der Wirtschaftskammer Österreich (WKO)</h2>
        <p>
          Die Mitgliedschaft in der Wirtschaftskammer Österreich (WKO) ist für
          die meisten Gewerbetreibenden verpflichtend.
        </p>
        <ul>
          <li>
            Sie profitieren von Beratung, Schulungen und weiteren
            Dienstleistungen.
          </li>
          <li>
            Die Mitgliedsbeiträge richten sich nach Ihrem Gewerbe und Einkommen.
          </li>
        </ul>
        <p>
          <a href="https://www.wko.at">Mitgliedschaft in der WKO</a>
        </p>

        <h2>7. Geschäftsunterlagen und Rechnungswesen</h2>
        <ul>
          <li>
            Führen Sie von Anfang an korrekte Buchhaltungsunterlagen.
            Einzelunternehmer können oft die Einnahmen-Ausgaben-Rechnung
            verwenden.
          </li>
          <li>
            Nutzen Sie ein Buchhaltungsprogramm, um Einnahmen und Ausgaben
            übersichtlich zu erfassen.
          </li>
        </ul>

        <h3>Hilfreiche Tipps</h3>
        <ol>
          <li>
            Planen Sie im Voraus: Stellen Sie sicher, dass Sie alle
            erforderlichen Unterlagen vorbereitet haben, um Verzögerungen zu
            vermeiden.
          </li>
          <li>
            Kleinunternehmerregelung: Prüfen Sie, ob die
            Kleinunternehmerregelung für Sie infrage kommt, um Bürokratie zu
            reduzieren.
          </li>
          <li>
            Beratung nutzen: Lassen Sie sich bei Bedarf von einem Steuerberater
            oder der WKO beraten.
          </li>
        </ol>

        <h3>Hilfreiche Links</h3>
        <ul>
          <li>
            <a href="https://www.wko.at">WKO - Unternehmensgründung</a>
          </li>
          <li>
            <a href="https://www.bmf.gv.at">Finanzamt</a>
          </li>
          <li>
            <a href="https://www.svs.at">SVS</a>
          </li>
          <li>
            <a href="https://www.oesterreich.gv.at">Gewerbeanmeldung</a>
          </li>
        </ul>

        <h3>Haftungsausschluss</h3>
        <p>
          Dieser Text dient ausschließlich Informationszwecken und soll Ihnen
          als erste Orientierung dienen. Für detaillierte und rechtlich
          verbindliche Informationen wenden Sie sich bitte an die zuständigen
          Behörden oder einen rechtlichen Experten.
        </p>
      </>
    ),
  },
  notFound: "Nachrichten nicht gefunden",
};

export const NewsResourcesEN: INewsResources = {
  servico: {
    title: "Welcome to servi.co",
    text: () => (
      <>
        <p>Dear Service Providers,</p>
        <p>
          Welcome to <strong>servi.co</strong>, the online intermediary platform
          that connects service providers of all kinds with households or
          businesses that need your services.
        </p>
        <section>
          <h2>Our Special Offer</h2>
          <p>
            We warmly invite you to join <strong>servi.co</strong> and take
            advantage of our current special offer:
          </p>
          <p>
            <strong>FREE AND NON-BINDING MEMBERSHIP UNTIL 2026</strong>
          </p>
          <p>
            This exclusive offer is available to all service providers who
            register by <strong>March 31, 2025</strong>.
          </p>
          <p>
            After the free trial period ends, you can decide whether you want to
            continue using the platform.
          </p>
        </section>
        <section>
          <h2>Learn More</h2>
          <p>
            Learn more about us on the{" "}
            <a href="https://servi.co/aboutus">ABOUT US</a> page and check out
            our{" "}
            <a
              href="https://servi.co/pdf/TermsConditions.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms and Conditions
            </a>{" "}
            for all details.
          </p>
        </section>
        <section>
          <h2>Join Us Today</h2>
          <p>
            Join <strong>servi.co</strong> today and take your business to the
            next level!
          </p>
          <p>We wish you great success! 😊</p>
        </section>
      </>
    ),
  },
  einzelunternehmen: {
    title:
      "Step-by-Step Guide to Registering a Sole Proprietorship (Einzelunternehmen) in Austria",
    text: () => (
      <>
        <p>
          Starting a sole proprietorship (Einzelunternehmen) in Austria is
          relatively straightforward. Below, you will find a step-by-step guide,
          along with helpful links and tips to simplify the process.
        </p>
        <h2>1. Check the Requirements</h2>
        <ul>
          <li>
            <strong>Industry-Specific Requirements:</strong> Verify whether your
            planned business activity requires specific licenses or
            certifications, such as a Meisterbrief (master craftsman
            certificate) for regulated trades (e.g., electricians, plumbers).
          </li>
          <li>
            <strong>Legal Form:</strong> As a sole proprietor, you do not need a
            separate legal entity, as you operate as a natural person.
          </li>
        </ul>
        <p>
          <a href="https://www.wko.at">Regulated and Free Trades – WKO</a>
        </p>
        <h2>2. Business Registration (Gewerbeanmeldung)</h2>
        <p>
          You must register your business with the relevant authority, which can
          be done online or in person.
        </p>
        <ul>
          <li>
            The responsible authority depends on your place of residence and may
            be the District Authority (Bezirkshauptmannschaft), the Magistrate,
            or the Municipal Office (Stadtgemeinde).
          </li>
          <li>
            Required Documents:
            <ul>
              <li>GISA Form (available at the authority or online)</li>
              <li>Valid Photo ID (passport or national ID card)</li>
              <li>Residence Registration Form (Meldezettel)</li>
              <li>Proof of necessary qualifications (for regulated trades)</li>
            </ul>
          </li>
        </ul>
        <p>
          <a href="https://www.oesterreich.gv.at">
            Online Business Registration - Austria
          </a>
        </p>
        <h2>3. Registering with the Tax Office (Finanzamt)</h2>
        <p>
          After registering your business, you will automatically receive a
          notification from the Tax Office (Finanzamt). You must apply for a tax
          number (Steuernummer) to report taxable income.
        </p>
        <ul>
          <li>
            You must complete and submit the Verf 24 form for tax registration.
          </li>
          <li>
            Indicate whether you are subject to VAT (Umsatzsteuerpflicht) – the
            threshold is EUR 35,000 per year.
          </li>
        </ul>
        <p>
          <a href="https://www.bmf.gv.at">Tax Forms and Information - BMF</a>
        </p>
        <h2>
          4. Registration with Social Security for the Self-Employed (SVS)
        </h2>
        <p>
          All sole proprietors are required to register with the Social
          Insurance Institution for the Self-Employed (SVS).
        </p>
        <ul>
          <li>
            You will receive an insurance number and pay contributions for
            health, accident, and pension insurance.
          </li>
          <li>
            Registration is usually automatic after business registration, but
            it is advisable to confirm its completion.
          </li>
        </ul>
        <p>
          <a href="https://www.svs.at">SVS - Self-Employed Registration</a>
        </p>
        <h2>5. VAT Registration (if applicable)</h2>
        <p>
          If your annual revenue exceeds EUR 35,000, you must register for
          Value-Added Tax (VAT or Umsatzsteuer). You will receive a VAT
          Identification Number (UID-Nummer).
        </p>
        <ul>
          <li>
            If your revenue is below this threshold, you can apply for the small
            business regulation (Kleinunternehmerregelung), which exempts you
            from VAT but also disallows input tax deductions.
          </li>
        </ul>
        <p>
          <a href="https://www.bmf.gv.at">VAT Registration - BMF</a>
        </p>
        <h2>6. Membership with the Austrian Economic Chamber (WKO)</h2>
        <p>
          Most businesses are required to become members of the Austrian
          Economic Chamber (WKO).
        </p>
        <ul>
          <li>
            Membership provides access to advisory services, training, and other
            resources.
          </li>
          <li>Membership fees depend on your industry and income level.</li>
        </ul>
        <p>
          <a href="https://www.wko.at">WKO Membership</a>
        </p>
        <h2>7. Business Documentation and Accounting</h2>
        <ul>
          <li>
            Maintain Proper Accounting Records: Sole proprietors can usually use
            single-entry accounting (Einnahmen-Ausgaben-Rechnung).
          </li>
          <li>
            Use Accounting Software: To keep track of income and expenses
            efficiently.
          </li>
        </ul>
        <h3>Helpful Tips</h3>
        <ol>
          <li>
            Plan Ahead: Ensure all necessary documents are prepared in advance
            to avoid delays.
          </li>
          <li>
            Small Business Regulation: Check whether you qualify for the
            Kleinunternehmerregelung to reduce administrative workload.
          </li>
          <li>
            Seek Professional Advice: Consult a tax advisor or the WKO if
            needed.
          </li>
        </ol>
        <h3>Helpful Links</h3>
        <ul>
          <li>
            <a href="https://www.wko.at">WKO - Starting a Business</a>
          </li>
          <li>
            <a href="https://www.bmf.gv.at">Austrian Tax Office (BMF)</a>
          </li>
          <li>
            <a href="https://www.svs.at">
              SVS - Social Insurance for the Self-Employed
            </a>
          </li>
          <li>
            <a href="https://www.oesterreich.gv.at">
              Online Business Registration
            </a>
          </li>
        </ul>
        <h3>Disclaimer</h3>
        <p>
          This text is for informational purposes only and serves as a general
          guideline. For detailed and legally binding information, please
          contact the relevant authorities or consult a legal expert.
        </p>
      </>
    ),
  },
  risiken: {
    title:
      "The Risks and Consequences of Providing Unregistered Services in Austria",
    text: () => (
      <>
        <p>
          Austria is known for its high standards in business practices and a
          strict legal framework. While it may be tempting to offer services
          without fulfilling all legal requirements, such actions can have
          significant consequences. This text highlights the legal and financial
          risks associated with the lack of necessary certifications, undeclared
          work, and tax evasion.
        </p>
        <section>
          <h2>
            Consequences of Providing Services Without Necessary Certificates
          </h2>
          <p>
            In Austria, many professions require specific certificates or
            qualifications to ensure public safety, high-quality standards, and
            consumer protection. Examples include electricians, plumbers, and
            healthcare professionals.
          </p>
          <h3>Potential Risks:</h3>
          <ul>
            <li>
              <strong>Legal Penalties:</strong> Working without the required
              certifications is illegal and can result in fines, legal
              prosecution, or even a business ban.
            </li>
            <li>
              <strong>Reputational Damage:</strong> Clients who discover that
              you lack the necessary certifications may lose trust in your
              services.
            </li>
            <li>
              <strong>Liability Issues:</strong> In case of accidents or damage,
              you may be held personally liable without the protection of valid
              insurance or compliance with legal standards.
            </li>
          </ul>
          <h3>Example:</h3>
          <p>
            An uncertified electrician performing installations may fail to meet
            safety standards. This increases the risk of accidents, and the
            resulting liabilities could include financial compensation claims or
            even criminal charges.
          </p>
        </section>
        <section>
          <h2>The Dangers of Undeclared Work</h2>
          <p>
            Engaging in undeclared work may seem appealing in the short term but
            carries severe long-term consequences.
          </p>
          <h3>Key Risks:</h3>
          <ul>
            <li>
              <strong>High Fines and Criminal Prosecution:</strong> Austrian
              authorities actively monitor and prosecute tax evasion and
              undeclared work. Penalties can include hefty fines and
              imprisonment.
            </li>
            <li>
              <strong>No Legal Protection:</strong> Without a formal business
              structure, you lack legal protection to resolve disputes or
              enforce contracts.
            </li>
            <li>
              <strong>No Access to Social Benefits:</strong> Undeclared work
              means missing out on contributions to social security, pension,
              and health insurance.
            </li>
            <li>
              <strong>Reputation and Trust:</strong> Being identified as an
              undeclared worker can permanently damage your reputation in the
              industry.
            </li>
          </ul>
          <h3>Example:</h3>
          <p>
            A tradesperson caught engaging in undeclared work could face fines
            amounting to several thousand euros and be required to pay back
            taxes along with additional penalties.
          </p>
        </section>
        <section>
          <h2>Helpful Links and Resources</h2>
          <ul>
            <li>
              <a href="https://www.wko.at">WKO - Starting a Business</a>
            </li>
            <li>
              <a href="https://www.bmf.gv.at">Austrian Tax Authority (BMF)</a>
            </li>
            <li>
              <a href="https://www.oesterreich.gv.at">E-Government Austria</a>
            </li>
            <li>
              <a href="https://www.svs.at">
                SVS - Social Insurance for the Self-Employed
              </a>
            </li>
          </ul>
        </section>
        <section>
          <h2>Disclaimer</h2>
          <p>
            This text is intended for informational purposes only. While we
            strive to provide accurate information, we cannot guarantee its
            correctness. The content is based on our research and may not cover
            all aspects or changes in legislation. For detailed and personalized
            information, we recommend contacting the relevant institutions and
            authorities directly.
          </p>
        </section>
      </>
    ),
  },
  notFound: "News not found",
};
