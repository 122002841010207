import img1 from "./images/img1.png";
import img2de from "./images/img2_de.png";
import img2en from "./images/img2_en.png";
import img3en from "./images/img3_en.jpg";
import img4de from "./images/img4_de.jpeg";
import img4en from "./images/img4_en.jpg";

export const FaqResourcesEN: IFaqResources = {
  title: "FAQ",
  modal: {
    title: "E-Mail sent.",
    text: "",
    button: "Done",
  },
  errors: {
    message: "E-Mail could not be sent!",
  },
  help: [
    {
      id: 1,
      question: "How to join servi.co as a Service Provider?",
      answer: () => (
        <>
          <p>
            You can join servi.co easy and withing few minutes. To register as a
            service provider at servi.co, you will need the following:
          </p>
          <li>E-Mail address</li>
          <li>Phone number</li>
          <br />
          <p>
            When creating the account at servi.co, you need to follow the few
            mandatory steps:
          </p>
          <li>ACCOUNT DETAILS section</li>
          <li>Select SERVICE PROVIDER as the account type.</li>
          <li>Provide a full name.</li>
          <li>
            If you're a company, select "Are you a company?" checkbox and
            provide your company name.
          </li>
          <li>Provide your mobile phone number in the "Phone number" field.</li>
          <li>
            Provide an E-Mail address for your account in the "E-Mail" field.
            Please note, it should be the E-Mail address that you have access to
            as this will be used for the communication between you and the
            platform.
          </li>
          <li>
            Choose a password for your account and enter it in the "Password"
            field.
          </li>
          <li>Repeat the same password in the "Repeat password" field.</li>
          <li>
            Select service categorie(s) that you offer in the "Categories"
            field.
          </li>
          <li>
            Select region(s) where you offer your service(s) in the "Area"
            field.
          </li>
          <li>
            Mark the “I’m over 18+” field. Only adult persons are allowed to use
            the platform.
          </li>
          <li>ADDRESS section</li>
          <li>
            Select the city of your- or your company residence in the "City"
            field.
          </li>
          <li>
            Select your- or your company ZIP code in the "ZIP code" field.
          </li>
          <li>Select "I accept Terms and Conditions" checkbox.</li>
          <br />
          <p>
            All other fields are optional and can be updated anytime under the
            "My Account" page.
          </p>
          <li>Finally, click the "Sign up" button.</li>
          <li>
            The last step is to confirm your E-Mail address via the link that
            you will receive on the E-Mail that you provided during the
            registration. If you don't find the confirmation email in your
            Inbox, please check the Spam mailbox. After you confirm your email,
            you will be succesfully registered at servi.co
          </li>
        </>
      ),
    },
    {
      id: 2,
      question: "How to join servi.co as a Client?",
      answer: () => (
        <>
          <p>
            You can join servi.co easy and withing few minutes. To register as a
            client at servi.co, you will need:
          </p>
          <li>E-Mail address</li>
          <li>Phone number</li>
          <br />
          <p>
            When creating the account at Servico, you need to follow these
            mandatory steps:
          </p>
          <li>ACCOUNT DETAILS section</li>
          <li>Select CLIENT as the account type.</li>
          <li>Provide a full name.</li>
          <li>
            Provide an E-Mail address for your account in the "E-Mail" field.
            Please note, it should be the E-Mail address that you have access to
            as this will be used for the communication between you and the
            platform.
          </li>
          <li>
            Choose a password for your account and enter it in the "Password"
            field.
          </li>
          <li>Repeat the same password in the "Repeat password" field.</li>
          <li>
            Checkout “I’m over 18+” field. Only adult persons are allowed to use
            the platform.
          </li>
          <li>ADDRESS section</li>
          <li>Select the city of your residence in the "City" field.</li>
          <li>
            Select the ZIP code of your residence in the "ZIP code" field.
          </li>
          <li>Select "I accept Terms and Conditions" checkbox.</li>
          <br />
          <p>
            All other fields are optional and can be updated anytime under "My
            Account" page.
          </p>
          <li>Finally, click the "Sign up" button.</li>
          <li>
            The last step is to confirm your E-Mail address via the link that
            you will receive on the E-Mail that you provided during the
            registration. If you don't find the confirmation email in your
            Inbox, please check the Spam mailbox. After you confirm your email,
            you will be succesfully registered at servi.co
          </li>
        </>
      ),
    },
    {
      id: 3,
      question: "How to Create New Task?",
      answer: () => (
        <>
          <p>
            To create a new task, please navigate to New Task” section in the
            navigation bar. When the task creation form is open, it needs the
            following details to be provided:
          </p>
          <li>Title of the task in the "Title" field.</li>
          <li>Short description of the task in the "Description" field.</li>
          <li>Select the task category in the "Category" field.</li>
          <li>
            Select the city where the task should be done in the "City" field.
          </li>
          <li>
            Select the ZIP code where the task should be done in the "ZIP Code"
            field.
          </li>
          <br />
          <p>All other fields are optional.</p>
          <li>
            When the task is ready click the "Task Preview" button to preview
            your task details. If everything is correct you should confirm by
            clicking the "Create Task" button. If you want to go back and edit
            the task, just click the "Edit Task".
          </li>
        </>
      ),
    },
    {
      id: 4,
      question: "How to register a service company?",
      answer: () => (
        <>
          <p>
            If you’re a service provider and you want to register your company,
            we provide you a short summary of where to start. Please be informed
            that the information provide in this text are a result of our search
            and interpretation and that we do not guarantee for the correctness
            of the information. This serves just as a starting point on your
            journey towards your company foundation and we hope it can help to
            acquire the initial information.
          </p>
          If you are just starting with your business, maybe it makes more sense
          that you start as a small business owner (it calls Kleinunternehmen in
          Austria), because it gives you a lot of ease regarding the costs. As
          you advance and make more revenue, you are changing your legal form as
          the company. More details about starting the small business you can
          find under the links:
          <li>
            Gewerbe anmelden - Voraussetzungen zum Gewerbeschein - Österreich
            (finanz.at)
          </li>
          <li>
            Kleinunternehmerregelung 2023 - Kleinunternehmer - Österreich
            (finanz.at)
          </li>
          <br />
          <p>
            At any time, you can also make your free appointment at chamber of
            commerce (Wirtschaftskammer) by calling a free phone number +43 1
            514 50 0 or via email info@wkw.at. At the appointment, you can ask
            any question and discover more details about starting your own
            business.
          </p>
        </>
      ),
    },
    {
      id: 5,
      question: "How to save Servi.co shortcut to your Home screen?",
      answer: () => (
        <>
          <p>
            <b>For iOS (Apple) users:</b>
          </p>
          <p>
            1. Tap the Share icon.
            <br />
            <img
              src={img1}
              alt="Teilen Symbol"
              style={{
                maxHeight: "100px",
                marginBottom: "2rem",
                marginTop: "1rem",
              }}
            />
          </p>
          <p>
            2. Tap „Add to Home Screen“.
            <br />
            <img
              src={img2en}
              alt="Add Button"
              style={{
                maxHeight: "300px",
                marginBottom: "2rem",
                marginTop: "1rem",
              }}
            />
          </p>
          <p>3. Tap the Add Button.</p>
          <br />
          <p>
            <b>For Android users:</b>
          </p>
          <p>
            1. Tap the Menu icon (three vertical dots in the top-right corner).
          </p>
          <img
            src={img3en}
            alt="Add Button Android"
            style={{
              maxHeight: "300px",
              maxWidth: "250px",
              marginBottom: "2rem",
              marginTop: "1rem",
            }}
          />
          <p>2. Select “Add to Home screen”.</p>
          <img
            src={img4en}
            alt="Hinzufügen Knopf Android"
            style={{
              maxHeight: "300px",
              maxWidth: "250px",
              marginBottom: "2rem",
              marginTop: "1rem",
            }}
          />
          <p>3. Tap “Add”.</p>
        </>
      ),
    },
  ],
  contactUs: {
    email: {
      label: "Email",
      placeholder: "Enter your E-Mail",
      error: "Please enter a valid E-Mail address",
      name: "email",
      validation: "The entered E-Mail address is not valid",
    },
    message: {
      label: "Message",
      placeholder: "Enter your message",
      error: "Please enter a valid message",
      name: "message",
    },
    contact: "Contact us: contact@servi.co",
    button: "Message us",
  },
};

export const FaqResourcesDE: IFaqResources = {
  title: "FAQ",
  modal: {
    title: "E-Mail geschickt.",
    text: "",
    button: "Erledigt",
  },
  errors: {
    message: "E-Mail konnte nicht geschickt werden!",
  },
  help: [
    {
      id: 1,
      question: "Wie registriere ich mich als Dienstleister:in bei servi.co?",
      answer: () => (
        <>
          <p>
            Sie können sich einfach und in wenigen Minuten bei servi.co
            anmelden. Um sich als Dienstleister:in bei servi.co zu registrieren,
            benötigen Sie:
          </p>
          <li>E-Mail-Adresse</li>
          <li>Telefonnummer</li>
          <br />
          <p>
            Beim Erstellen des Kontos bei servi.co müssen Sie die folgenden
            Pflichtschritte ausführen:
          </p>
          <li>Abschnitt KONTOINFORMATIONEN</li>
          <li>Wählen Sie "DIENSTLEISTER:IN" als Kontotyp.</li>
          <li>Geben Sie einen vollständigen Namen an.</li>
          <li>
            Wenn Sie ein Unternehmen sind, wählen Sie das Kontrollkästchen "Sind
            Sie eine Firma?" und geben Sie den Namen Ihres Unternehmens an.
          </li>
          <li>Geben Sie Ihre Handynummer im Feld "Telefonnummer" an.</li>
          <li>
            Geben Sie eine E-Mail-Adresse für Ihr Konto im Feld "E-Mail" an.
            Bitte beachten Sie, dass es sich um die E-Mail-Adresse handeln
            sollte, auf die Sie Zugriff haben, da diese für die Kommunikation
            zwischen Ihnen und der Plattform verwendet wird.
          </li>
          <li>
            Wählen Sie ein Passwort für Ihr Konto und geben Sie es im Feld
            "Passwort" ein.
          </li>
          <li>
            Wiederholen Sie dasselbe Passwort im Feld "Passwort wiederholen".
          </li>
          <li>
            Wählen Sie im Feld "Kategorien", die Dienstkategorie(n), die Sie
            anbieten.
          </li>
          <li>
            Wählen Sie im Feld "Ort Selektieren", die Region(en), in denen Sie
            Ihre Dienstleistung(en) anbieten.
          </li>
          <li>Akzeptieren Sie die Allgemeinen Geschäftsbedingungen</li>
          <li>
            Selektieren Sie das Kontrollkästchen "Ich bin über 18". Nur
            volljährige Personen dürfen die Plattform nutzen.
          </li>
          <li>Abschnitt ADRESSE:</li>
          <li>
            Wählen Sie die Stadt Ihres Wohn- oder Firmensitzes im Feld "Stadt".
          </li>
          <li>
            Wählen Sie Ihre Postleitzahl oder die Ihres Unternehmens im Feld
            "PLZ".
          </li>
          <li>
            Selektieren Sie das Kontrollkästchen "Ich akzeptiere die Allgemeinen
            Geschäftsbedingungen".
          </li>
          <br />
          <p>
            Alle anderen Felder sind optional und können jederzeit unter der
            Seite "Mein Konto" aktualisiert werden.
          </p>
          <li>Klicken Sie abschließend auf die Schaltfläche "Registrieren".</li>
          <li>
            Der letzte Schritt besteht darin, Ihre E-Mail-Adresse über den Link
            zu bestätigen, den Sie an die während der Registrierung angegebene
            E-Mail-Adresse erhalten. Wenn Sie die Bestätigungs-E-Mail nicht in
            Ihrem Posteingang finden, überprüfen Sie bitte den Spam-Ordner.
            Nachdem Sie Ihre E-Mail bestätigt haben, sind Sie erfolgreich bei
            servi.co registriert.
          </li>
        </>
      ),
    },
    {
      id: 2,
      question: "Wie registriere ich mich als Kund:in bei servi.co?",
      answer: () => (
        <>
          <p>
            Sie können sich einfach und in wenigen Minuten bei servi.co
            anmelden. Um sich als Kund:in bei servi.co zu registrieren,
            benötigen Sie:
          </p>
          <li>E-Mail-Adresse</li>
          <li>Telefonnummer</li>
          <br />
          <p>
            Beim Erstellen des Kontos bei Servico müssen Sie die folgenden
            Pflichtschritte ausführen:
          </p>
          <li>Abschnitt KONTOINFORMATIONEN</li>
          <li>Wählen Sie "KUND:IN" als Kontotyp.</li>
          <li>Geben Sie einen vollständigen Namen an.</li>
          <li>
            Geben Sie eine E-Mail-Adresse für Ihr Konto im Feld "E-Mail" an.
            Bitte beachten Sie, dass es sich um die E-Mail-Adresse handeln
            sollte, auf die Sie Zugriff haben, da diese für die Kommunikation
            zwischen Ihnen und der Plattform verwendet wird.
          </li>
          <li>
            Wählen Sie ein Passwort für Ihr Konto und geben Sie es im Feld
            "Passwort" ein.
          </li>
          <li>
            Wiederholen Sie dasselbe Passwort im Feld "Passwort wiederholen".
          </li>
          <li>
            Selektieren Sie das Kontrollkästchen "Ich bin über 18". Nur
            volljährige Personen dürfen die Plattform nutzen.
          </li>
          <li>Abschnitt ADRESSE</li>
          <li>Wählen Sie die Stadt Ihres Wohnsitzes im Feld "Stadt".</li>
          <li>Wählen Sie die Postleitzahl Ihres Wohnsitzes im Feld "PLZ".</li>
          <li>
            Selektieren Sie das Kontrollkästchen "Ich akzeptiere die Allgemeinen
            Geschäftsbedingungen".
          </li>
          <br />
          <p>
            Alle anderen Felder sind optional und können jederzeit unter der
            Seite "Mein Konto" aktualisiert werden.
          </p>
          <li>
            Der letzte Schritt besteht darin, Ihre E-Mail-Adresse über den Link
            zu bestätigen, den Sie an die während der Registrierung angegebene
            E-Mail-Adresse erhalten. Wenn Sie die Bestätigungs-E-Mail nicht in
            Ihrem Posteingang finden, überprüfen Sie bitte den Spam-Ordner.
            Nachdem Sie Ihre E-Mail bestätigt haben, sind Sie erfolgreich bei
            servi.co registriert.
          </li>
        </>
      ),
    },
    {
      id: 3,
      question: "Wie erstelle ich einen neuen Auftrag?",
      answer: () => (
        <>
          <p>
            Um einen neuen Auftrag zu erstellen, navigieren Sie bitte zum
            Abschnitt „Neuer Auftrag“ in der Navigationsleiste. Wenn das
            Formular zur Auftragserstellung geöffnet ist, müssen die folgenden
            Details angegeben werden:
          </p>
          <li>Titel des Auftrags im Feld "Titel".</li>
          <li>Kurze Beschreibung des Auftrags im Feld "Beschreibung".</li>
          <li>Wählen Sie die Kategorie des Auftrags im Feld "Kategorie".</li>
          <li>
            Wählen Sie die Stadt, in der der Auftrag durchgeführt werden soll,
            im Feld "Stadt".
          </li>
          <li>
            Wählen Sie die Postleitzahl, in der der Auftrag durchgeführt werden
            soll, im Feld "PLZ".
          </li>
          <br />
          <p>Alle anderen Felder sind optional.</p>
          <li>
            Wenn der Auftrag fertig ist, klicken Sie auf die Schaltfläche
            „Auftrags-Vorschau“, um die Details Ihres Auftrags anzuzeigen. Wenn
            alles korrekt ist, bestätigen Sie dies, indem Sie auf die
            Schaltfläche „Auftrag erstellen“ klicken. Wenn Sie zur Bearbeitung
            des Auftrags zurückkehren möchten, klicken Sie einfach auf „Auftrag
            bearbeiten“.
          </li>
        </>
      ),
    },
    {
      id: 4,
      question: "Wie registriere ich ein Dienstleistungsunternehmen?",
      answer: () => (
        <>
          <p>
            Wenn Sie gerade erst mit Ihrem Unternehmen beginnen, ist es
            möglicherweise sinnvoller, als Kleinunternehmen zu beginnen, da Sie
            dadurch viel Kostenersparnis haben. Wenn Sie vorankommen und mehr
            Umsatz erzielen, ändern Sie auch die Rechtsform Ihres Unternehmens.
            Weitere Details zur Gründung des Kleinunternehmens finden Sie unter
            folgendem Link:
          </p>
          <li>
            Gewerbe anmelden - Voraussetzungen zum Gewerbeschein - Österreich
            (finanz.at)
          </li>
          <li>
            Kleinunternehmerregelung 2023 - Kleinunternehmer - Österreich
            (finanz.at)
          </li>
          <br />
          <p>
            Sie könnten auch jederzeit Ihren kostenlosen Termin bei der
            Wirtschaftskammer vereinbaren, indem Sie eine kostenlose
            Telefonnummer +43 1 514 50 0 anrufen oder via E-Mail info@wkw.at.
            Bei dem Termin können Sie alle Fragen stellen und weitere Details
            zur Unternehmensgründung erfahren.
          </p>
        </>
      ),
    },
    {
      id: 5,
      question:
        "Wie speichert man die Servi.co-Verknüpfung auf dem Startbildschirm?",
      answer: () => (
        <>
          <p>
            <b>Für iOS (Apple)-Nutzer:innen:</b>
          </p>
          <p>
            1. Tippen Sie auf das Teilen-Symbol.
            <br />
            <img
              src={img1}
              alt="Teilen Symbol"
              style={{
                maxHeight: "100px",
                marginBottom: "2rem",
                marginTop: "1rem",
                maxWidth: "250px",
              }}
            />
          </p>
          <p>
            2. Tippen Sie auf „Zum Home-Bildschirm“.
            <br />
            <img
              src={img2de}
              alt="Hinzufügen Knopf"
              style={{
                maxHeight: "300px",
                marginBottom: "2rem",
                marginTop: "1rem",
                maxWidth: "250px",
              }}
            />
          </p>
          <p>3. Tippen Sie „Hinzufügen“.</p>
          <br />
          <p>
            <b>Für Android-Nutzer:innen</b>
          </p>
          <p>
            1. Tippen Sie auf das Menü-Symbol (drei vertikale Punkte in der
            oberen rechten Ecke).
          </p>
          <img
            src={img3en}
            alt="Hinzufügen Knopf Android"
            style={{
              maxHeight: "300px",
              marginBottom: "2rem",
              maxWidth: "250px",
              marginTop: "1rem",
            }}
          />
          <p>2. Wählen Sie „Zum Startbildschirm hinzufügen“.</p>
          <img
            src={img4de}
            alt="Hinzufügen Knopf Android"
            style={{
              maxHeight: "300px",
              maxWidth: "250px",
              marginBottom: "2rem",
              marginTop: "1rem",
            }}
          />
          <p>3. Tippen Sie auf „Hinzufügen“.</p>
        </>
      ),
    },
  ],
  contactUs: {
    email: {
      label: "Email",
      placeholder: "Enter your E-Mail",
      error: "Please enter a valid E-Mail address",
      name: "email",
      validation: "The entered E-Mail address is not valid",
    },
    message: {
      label: "Message",
      placeholder: "Enter your message",
      error: "Please enter a valid message",
      name: "message",
    },
    contact: "Contact us: contact@servi.co",
    button: "Message us",
  },
};

interface Modal {
  title: string;
  text: string;
  button: string;
}

interface HelpItem {
  id: number;
  question: string;
  answer: () => JSX.Element;
  titleClass?: string;
  textClass?: string;
}

interface ContactField {
  label: string;
  placeholder: string;
  error: string;
  name: string;
  validation?: string;
}

interface ContactUs {
  email: ContactField;
  message: ContactField;
  contact: string;
  button: string;
}

interface ErrorFields {
  message: string;
}

export interface IFaqResources {
  title: string;
  modal: Modal;
  help: HelpItem[];
  contactUs: ContactUs;
  errors: ErrorFields;
}
