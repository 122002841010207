export const MessagesPageResourcesEN: IMessagesPageResources = {
  title: "My Messages",
  placeholderText: "No chats at the moment.",
};

export const MessagesPageResourcesDE: IMessagesPageResources = {
  title: "Meine Nachrichten",
  placeholderText: "Momentan keine Chats.",
};

export interface IMessagesPageResources {
  title: string;
  placeholderText: string;
}
