// Good Documentation and Examples: https://phrase.com/blog/posts/localizing-react-apps-with-i18next/

import { AboutUsDE, AboutUsEN } from "../pages/AboutUs/AboutUs.resources";
import {
  AccountPageResourcesDE,
  AccountPageResourcesEN,
} from "../pages/account/AccountPage/AccountPage.resources";
import {
  CarouselTextDE,
  CarouselTextEN,
} from "../components/Carousel/Carousel.resources";
import {
  CategoriesResourcesDE,
  CategoriesResourcesEN,
} from "../pages/creation/Categories/Categories.resources";
import {
  CreationPageResourcesDE,
  CreationPageResourcesEN,
} from "../pages/creation/CreationPage/CreationPage.resources";
import {
  EditOfferPageResourcesDE,
  EditOfferPageResourcesEN,
} from "../pages/creation/EditOfferPage/EditOfferPage.resources";
import {
  Error404ResourcesDE,
  Error404ResourcesEN,
} from "../pages/error/Error404.resources";
import { FaqResourcesDE, FaqResourcesEN } from "../pages/faq/Faq.resources";
import {
  FooterResourcesDE,
  FooterResourcesEN,
} from "../layouts/Footer.resources";
import { HomeResourcesDE, HomeResourcesEN } from "../pages/home/Home.resources";
import {
  MenuItemsResourcesDE,
  MenuItemsResourcesEN,
} from "../layouts/TwoColumn/MenuItems.resources";
import {
  MessagesPageResourcesDE,
  MessagesPageResourcesEN,
} from "../pages/account/MessagesPage/MessagesPage.resources";
import { NewsResourcesDE, NewsResourcesEN } from "../pages/News/News.resources";
import {
  OfferPageResourcesDE,
  OfferPageResourcesEN,
} from "../pages/creation/OfferPage/OfferPage.resources";
import {
  PricingResourcesDE,
  PricingResourcesEN,
} from "../pages/pricing/Pricing.resources";
import {
  ProfileResourcesDE,
  ProfileResourcesEN,
} from "../pages/profile/Profile.resources";
import {
  ReviewPageResourcesDE,
  ReviewPageResourcesEN,
} from "../pages/creation/ReviewPage/ReviewPage.resources";
import {
  SearchPageResourcesDE,
  SearchPageResourcesEN,
} from "../pages/search/SearchPage/SearchPage.resources";
import { authDE, authEN } from "../pages/auth/Auth.resources";

import LanguageDetector from "i18next-browser-languagedetector";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";

// Backend helps loading texts from remote server
// import Backend from "i18next-http-backend";
// LanguageDetector helps detect the default language from the browser

const resources = {
  en: {
    // do not change namespace "translation", otherwise you have to set option "ns" in i18n config
    translation: {
      auth: authEN,
      home: HomeResourcesEN,
      faq: FaqResourcesEN,
      error404: Error404ResourcesEN,
      account: AccountPageResourcesEN,
      messages: MessagesPageResourcesEN,
      creation: CreationPageResourcesEN,
      menuItems: MenuItemsResourcesEN,
      offer: OfferPageResourcesEN,
      profile: ProfileResourcesEN,
      review: ReviewPageResourcesEN,
      search: SearchPageResourcesEN,
      footer: FooterResourcesEN,
      editOffer: EditOfferPageResourcesEN,
      categories: CategoriesResourcesEN,
      pricing: PricingResourcesEN,
      aboutus: AboutUsEN,
      carousel: CarouselTextEN,
      news: NewsResourcesEN,
    },
  },
  de: {
    translation: {
      auth: authDE,
      home: HomeResourcesDE,
      faq: FaqResourcesDE,
      error404: Error404ResourcesDE,
      account: AccountPageResourcesDE,
      messages: MessagesPageResourcesDE,
      creation: CreationPageResourcesDE,
      menuItems: MenuItemsResourcesDE,
      offer: OfferPageResourcesDE,
      profile: ProfileResourcesDE,
      review: ReviewPageResourcesDE,
      search: SearchPageResourcesDE,
      footer: FooterResourcesDE,
      editOffer: EditOfferPageResourcesDE,
      categories: CategoriesResourcesDE,
      pricing: PricingResourcesDE,
      aboutus: AboutUsDE,
      carousel: CarouselTextDE,
      news: NewsResourcesDE,
    },
  },
};

i18next
  // Binding our instance to an internal store
  .use(initReactI18next)
  // .use(Backend)
  // TODO: Language detector is not saving the cookies
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: "de",
    // Only en for example, without en-US or en-UK
    load: "languageOnly",
    // Allowed languages | how to get them in our components: i18n.options.supportedLngs
    supportedLngs: ["en", "de"],
    debug: true,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;
